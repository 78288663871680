/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 服务商相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class SpApiSet {
  constructor () {
    this.spList = BaseApi.createModel('获取服务商信息列表', '/opmp/sp/list').setMethod(0);
    this.spAdd = BaseApi.createModel('新增服务商信息', '/opmp/sp/add');
    this.spChangeStatus = BaseApi.createModel('服务商修改状态', '/opmp/sp/changeStatus').setMethod(2);
    this.spInfo = BaseApi.createModel('获取服务商详情', '/opmp/sp/info').setMethod(0, false);
    this.spAdminSet = BaseApi.createModel('设置服务商管理员-登录账号', '/opmp/sp/admin/set');
    this.spConcatSet = BaseApi.createModel('设置服务商管理员-联系人', '/opmp/sp/contact/set');
    this.spMerchantAdd = BaseApi.createModel('设置服务商商户号', '/opmp/sp/merchant/add');
    this.spCredentialSet = BaseApi.createModel('设置服务商证件', '/opmp/sp/credential/add');
    this.spAppidSet = BaseApi.createModel('设置小程序', '/opmp/sp/appid/set');
  }
}

export default new SpApiSet();
