import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isNumber, isValidString1, isPhone, isAlphanumeric } from '@/libs/regularUtils';
import md5 from 'md5';

export class ServiceDealerListModel extends BaseModel {
  constructor () {
    super();
    this.id = ''; // 服务商ID
    this.providerName = ''; // 服务商名称
    this.providerCode = ''; // 服务商ID
    this.principalType = ''; // 主体类型 主体类型(1：公司/企业, 2：小微, 3：个人)
    this.principalName = ''; // 主题类型名称
    this.principalTypeName = ''; // 主题类型名称
    this.adminUser = null; // 登录账号对象
    this.contact = ''; // 联系人
    this.mobile = ''; // 联系人电话
    this.status = ''; // 状态 0: 停用，1：启用
    this.createTime = ''; // 创建时间
    this.platformName = ''; // 上级
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5AdminUserName = ''; // 服务商管理员登录账号
    this.h5AdminCanChange = true; // 只有没有设置过登录账号时，才可以设置
  }

  initData (resData) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.h5AdminUserName = resData.adminUser ? resData.adminUser.username : '';
    this.h5AdminCanChange = !this.h5AdminUserName;
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        providerName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20) + 10),
        providerCode: BaseModel.getFakeNumber(99999999, 0),
        principalType: i % 2 + 1,
        principalTypeName: i % 2 ? '公司企业' : '个人',
        adminUser: {
          userName: '登录账号'
        },
        contact: BaseModel.getFakeTitle(5),
        mobile: '139042880' + (BaseModel.getFakeNumber(80, 0) + 10),
        status: i % 2,
        createTime: '2023-12-31 12:59:59'
      };
      list.push(dic);
    }
    return list;
  }
}
export class ServiceDealerDetailModel extends ServiceDealerListModel {
  constructor () {
    super();
    this.password = ''; // 登录密码(编辑账号时使用)
    this.credentialUrl = ''; // 服务商证件
    this.appId = ''; // appId
    this.merchants = [];
    this.h5PasswordAgain = ''; // 确认密码时使用
  }

  initData (resData) {
    super.initData(resData);
    // this.principalType = 3;
    return this;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    // 专项校验
    if (this.providerName.length < 6 || this.providerName.length > 40) {
      return BaseModel.getValidRes(false, '请输入6-40位服务商的名称');
    } else if (!isValidString1(this.providerName)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    return BaseModel.getValidRes(true, '', {
      providerName: this.providerName,
      principalType: this.principalType,
      ownerId: 1 // 默认填写1
    });
  }

  /**
   * 数据具体校验-账号设置
   * @returns {*}
   */
  checkValidAccountSubmit () {
    // 专项校验
    if (this.h5AdminUserName.length < 6 || this.h5AdminUserName.length > 20) {
      return BaseModel.getValidRes(false, '请输入6-20位登录账号');
    } else if (isNumber(this.h5AdminUserName)) {
      return BaseModel.getValidRes(false, '账号不可纯数字');
    } else if (!isAlphanumeric(this.password)) {
      return BaseModel.getValidRes(false, '6~8位密码，必须为数字字母组合');
    } else if (this.password !== this.h5PasswordAgain) {
      return BaseModel.getValidRes(false, '两次输入的密码不同，请重新输入');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      adminUserName: this.h5AdminUserName,
      password: md5(this.password)
    });
  }

  /**
   * 数据具体校验-联系人
   * @returns {*}
   */
  checkValidConcatSubmit () {
    // 专项校验
    if (!isPhone(this.mobile)) {
      return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
    } else if (this.contact.length < 1 || this.contact.length > 20) {
      return BaseModel.getValidRes(false, '联系人请输入1-20位');
    } else if (!isValidString1(this.contact)) {
      return BaseModel.getValidRes(false, '联系人请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      contact: this.contact,
      mobile: this.mobile,
      captcha: this.code,
      uuid: ''
    });
  }

  /**
   * 数据具体校验-appId
   * @returns {*}
   */
  checkValidAppIdSubmit () {
    if (!this.appId.startsWith('wx')) {
      return BaseModel.getValidRes(false, '请输入wx开头的小程序appId');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.id,
      appId: this.appId
    });
  }

  static createFakeData () {
    return this.createFakeDataList(1)[0];
  }

  static createAdditionModel () {
    return new this();
  }
}
