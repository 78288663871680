<!--
    Created by 程雨喵'mac on 2024/1/3.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-商户号设置
-->
<style lang="less">
.service-merchant-setting {
  .upload-box {
    width: 100%;
    padding: @containerGap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: @backColorNormal;
    border-radius: @borderRadiusMid;
    .upload-item-box {
      .flex-grow(49%);
      .upload-tit {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        font-weight: 600;
        color: @textColorLight;
      }
      .upload-outer {
        .display-flex();
        position: relative;
        background-color: @backColorStrong;
        border-radius: @borderRadiusMid;
        height: 120px;
        overflow: hidden;
        img {
          width: 100%;
        }
        &:hover {
          .hover-mask {
            opacity: 1;
          }
        }
        .file-empty-text {
          .display-flex();
          height: 100%;
          font-size: 12px;
          color: @textColorLight;
        }
        .hover-mask {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          .display-flex();
          border: 2px dashed @themeColor;
          background-color: rgba(255,255,255,0.7);
          border-radius: @borderRadiusMid;
          font-size: 12px;
          color: @textColorFlip;
          opacity: 0;
          .service-merchant-upload-btn {
            .display-flex();
            width: 50px;
            height: 20px;
            background-color: @finishColor;
            cursor: pointer;
          }
          .service-merchant-upload {
            margin-left: 40px;
            width: 50px;
            height: 20px;
            background-color: @themeColor;
            cursor: pointer;
          }
        }
      }
    }
    .upload-bottom {
      margin-top: 10px;
      .display-flex();
      .flex-grow(100%);
      justify-content: space-between;
      .upload-info {
        .display-flex();
        justify-content: flex-start;
        color: @textColorLight;
        font-size: 12px;
        line-height: 18px;
        .ivu-icon {
          opacity: 0.7;
        }
        p {
          margin-left: 5px;
        }
      }
    }
  }
  .already-upload {
    .label {
      color: @textColorLight;
    }
  }
  .merchant-table {
    flex: 1;
    border-radius: @borderRadiusMin;
    border: 1px solid @lineColorNormal;
    font-size: 12px;
    overflow: hidden;
    .tb-row {
      display: flex;
      border-bottom: 1px solid @lineColorNormal;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      &.tb-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
      }
      .tb-col {
        padding: 2px @containerGap;
        .display-flex();
        &-0{.flex-grow(20%)}
        &-1{.flex-grow(40%);}
        &-2{.flex-grow(40%)}
        &+.tb-col {
          border-left: 1px solid @lineColorNormal;
        }
        .edit-outer {
          .flex-grow(100%);
          .display-flex();
          .input-outer {
            flex: 1;
          }
          span.input-outer {
            padding-left: 10px;
            overflow: hidden;
            text-align: center;
          }
          .edit-btn {
            .flex-grow(20px);
            margin-left: 10px;
            .display-flex();
            white-space: nowrap;
            color: @textColorPrimary;
            text-decoration: underline;
            cursor: pointer;
            img {
              width: 16px;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="service-merchant-setting">
    <Form>
      <!--服务商证件-->
      <FormItem
        label="服务商证件:"
        :label-width="145"
        :required="true"
      >
        <div class="upload-box">
          <div
            class="upload-item-box"
          >
            <p class="upload-tit">
              {{ model.principalType === 3 ? '请上传身份证正反面' : '请上传营业执照' }}
            </p>
            <div class="upload-outer">
              <img
                v-if="model.credentialUrl"
                :src="model.credentialUrl"
              >
              <div
                v-else
                class="file-empty-text"
              >
                暂未设置
              </div>
              <div class="hover-mask">
                <span
                  class="service-merchant-upload-btn"
                  @click.stop="_clickShowCredentialUrl(model.credentialUrl)"
                >查看</span>
                <yg-upload
                  upload-key="credentialUrl"
                  class="service-merchant-upload"
                  @on-finish="onUploadFinish"
                >
                  上传
                </yg-upload>
              </div>
            </div>
          </div>
          <div class="upload-bottom">
            <div class="upload-info">
              <Icon type="md-alert" />
              <p>企业\小微请上传营业执照，个人请上传身份证正反面(身份证正反面请放到一张图片中)。</p>
            </div>
          </div>
        </div>
      </FormItem>
      <!--服务商支付商户号-->
      <FormItem
        label="服务商支付商户号:"
        :label-width="145"
        :required="true"
        class="margin-primary-top"
      >
        <div class="merchant-table">
          <div class="tb-row tb-header">
            <div
              v-for="(item, i) in tableColumn"
              :key="i"
              class="tb-col"
              :class="'tb-col-' + i"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-for="(item, i) in tableList"
            :key="'tr' + i"
            class="tb-row"
          >
            <div class="tb-col tb-col-0">
              {{ item.title }}
            </div>
            <div class="tb-col tb-col-1">
              <div
                class="edit-outer"
              >
                <div
                  v-if="item.isEditCode"
                  class="input-outer"
                >
                  <Input
                    v-model.trim="item.merchantCode"
                    placeholder="暂未设置"
                  />
                </div>
                <span
                  v-else
                  class="input-outer"
                >{{ item.merchantCode || '暂未设置' }}</span>

                <div
                  class="edit-btn"
                  @click="_clickEditCode(item)"
                >
                  <span v-if="item.isEditCode">完成</span>
                  <img
                    v-else
                    src="/icon/edit.png"
                  >
                </div>
              </div>
            </div>
            <div class="tb-col tb-col-2">
              <div
                class="edit-outer"
              >
                <div
                  v-if="item.isEditKey"
                  class="input-outer"
                >
                  <Input
                    v-model.trim="item.merchantSignKey"
                    placeholder="暂未设置"
                  />
                </div>
                <span
                  v-else
                  class="input-outer"
                >{{ item.merchantSignKey || '暂未设置' }}</span>

                <div
                  class="edit-btn"
                  @click="_clickEditKey(item)"
                >
                  <span v-if="item.isEditKey">完成</span>
                  <img
                    v-else
                    src="/icon/edit.png"
                  >
                </div>
              </div>
            </div>
            <!--<div class="tb-col tb-col-3">-->
            <!--  <yg-table-switch-->
            <!--    :value="item.statusSwitch"-->
            <!--    @on-change="onSwitchChange(item)"-->
            <!--  />-->
            <!--</div>-->
          </div>
        </div>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import SpApiSet from '@/request/api/SpApiSet';
import { mapActions } from 'vuex';
import md5 from 'md5';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      fileUploadDisabled: true,
      tableList: [],
      payTypeChannelList: [],
      tableColumn: ['支付渠道名称', '商户号', '支付秘钥']
    };
  },
  created () {
    this.getTableList();
  },
  methods: {
    ...mapActions(['getPayTypeChannelList']),
    onSwitchChange () {},
    getTableList () {
      this.getPayTypeChannelList().then(res => {
        const tableList = [];
        const merchants = this.model.merchants || [];
        if (Array.isArray(res)) {
          res.forEach(v => {
            const modelMerchant = merchants.find(m => (v.value + '') === (m.payChannelType + ''));
            const item = {};
            item.title = v.label;
            item.isEditCode = false;
            item.isEditKey = false;
            item.payChannelType = v.value;
            item.merchantCode = modelMerchant ? modelMerchant.merchantCode : '';
            item.merchantSignKey = modelMerchant ? modelMerchant.merchantSignKey : '';
            // 两个值的md5，用于查看值是否变动
            item.valueMd5 = md5(item.merchantCode + item.merchantSignKey);
            tableList.push(item);
          });
        }
        this.tableList = tableList;
      });
    },
    // 上传成功
    onUploadFinish ({ key, url }) {
      console.log(key);
      this.requestCredentialSet(key, url);
    },
    _clickEditCode (item) {
      if (item.isEditCode) {
        // 1.查看值是否有变化
        const valueMd5 = md5(item.merchantCode + item.merchantSignKey);
        if (item.valueMd5 !== valueMd5) {
          // 提交
          this.requestSubmit(item);
        } else {
          item.isEditCode = false;
        }
      } else {
        item.isEditCode = true;
      }
    },
    _clickEditKey (item) {
      if (item.isEditKey) {
        // 1.查看值是否有变化
        const valueMd5 = md5(item.merchantCode + item.merchantSignKey);
        if (item.valueMd5 !== valueMd5) {
          // 提交
          this.requestSubmit(item);
        } else {
          item.isEditKey = false;
        }
      } else {
        item.isEditKey = true;
      }
    },
    _clickShowCredentialUrl (e) {
      if (!e) return this.$Message.info('暂未设置');
      window.open(e);
    },
    requestCredentialSet (key, url) {
      const api = SpApiSet.spCredentialSet;
      api.params = {
        serviceProviderId: this.model.id,
        credentialType: 1,
        fileUrl: url
      };
      this.$http(api).then(res => {
        this.model[key] = url;
        this.$Message.success('操作成功');
      });
    },
    requestSubmit (item) {
      const api = SpApiSet.spMerchantAdd;
      api.params = {
        serviceProviderId: this.model.id,
        payChannelType: item.payChannelType,
        merchantCode: item.merchantCode,
        merchantSignKey: item.merchantSignKey
      };
      this.$http(api).then(res => {
        console.log(res);
        this.$Message.success('操作成功');
        item.isEditKey = false;
        item.isEditCode = false;
      });
    }
  }
};
</script>
