export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('providerName', '服务商名称', 160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('providerCode', '服务商编码', 130).setSpan({ canCopy: true }),
        // BaseTableColumn.initData('bb', '商户类型').setWidth(100).setSpan({}),
        BaseTableColumn.initData('principalTypeName', '服务商\n主体类型').setWidth(100).setSpan({}),
        BaseTableColumn.initData('platformName', '上级商户', 120).setSpan({}),
        BaseTableColumn.initData('h5AdminUserName', '登录账号', 100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('concat', '联系人').setWidth(160).setKeyValueList([
          { label: '名称：', labelWidth: 40, key: 'contact', attr: { canTooltip: true } },
          { label: '手机：', labelWidth: 40, key: 'mobile', attr: { canHidePhone: true } }
        ]),
        BaseTableColumn.initData('createTime', '创建时间').setWidth(160),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setWidth(70).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:serviceProvider:status'),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '登录账号设置', name: 0, permission: 'system:serviceProvider:admin:set' },
          { title: '联系人设置', name: 1, permission: 'system:serviceProvider:contact:set' },
          { title: '商户号设置', name: 2, permission: 'system:serviceProvider:merchantCode:set' },
          { title: '小程序设置', name: 3, permission: 'system:serviceProvider:appid:set' }
        ], ({ name }, row, index) => {
          this.dialog.settingIndex = name;
          this.requestDetail(row);
        })
      ]
    };
  }
};
