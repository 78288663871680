<!--
    Created by 程雨喵'mac on 2024/7/4.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">

</style>

<template>
  <div>
    <Form>
      <FormItem
        v-for="(sub, ind) in formItems"
        :key="ind"
        :label="sub.label"
        :label-width="sub.labelWidth"
        :required="sub.isRequired"
      >
        <Input
          v-if="sub.tagName === 'Input'"
          v-model="model[sub.key]"
          :placeholder="'请输入' + sub.label"
          :disabled="sub.disabled"
          @input="checkSubmit"
        />
        <yg-attribute-span
          v-else
          :can-hide-phone="true"
          :text="model[sub.key]"
          class="office-detail-text"
        />
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      // 表单数据
      formItems: [
        {
          label: '小程序APPID',
          labelWidth: 140,
          key: 'appId',
          tagName: 'Input',
          disabled: false,
          isRequired: true
        }
      ]
    };
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      const item = this.formItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
